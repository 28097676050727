import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
// import PropTypes from "prop-types";
// import Box from "@mui/material/Box";
import MDBox from "components/MDBox";
import { type loadObjectType } from "constant/Types";
import { Alert, type AlertColor, Dialog, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// import LoadingManager from "constant/LoadingManager";

/* const LoadComponent: React.FC<loadObjectType> = ({
  isAlert,
  isLoading,
  alertSeverity,
  message,
}) => { */
interface SettingsProps {
  handleLoad: (loadData: loadObjectType) => void;
}

type CombinedProps = loadObjectType & SettingsProps;
const LoadComponent: React.FC<CombinedProps> = ({
  isAlert,
  isLoading,
  message,
  alertSeverity,
  handleLoad,
}) => {
  //  const { isAlert, isLoading, message, alertSeverity, handleLoad } = loadData;
  // const loadingManager = LoadingManager();
  const [open, setOpen] = useState<boolean>(false);
  // const { isAlert, isLoading, message, alertSeverity } = loadingManager;
  useEffect(() => {
    if (isLoading || isAlert) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [isLoading, isAlert]);
  const handleClose = (): void => {
    setOpen(false);
    const alertSeverity: AlertColor = "error";
    const loadD = {
      isAlert: false,
      message: "",
      isLoading: false,
      alertSeverity,
    };
    handleLoad(loadD);
  };
  return (
    <Dialog open={open} fullWidth>
      <MDBox mt={3} mb={1} textAlign="center" sx={{ alignContent: "center" }}>
        {/* <Box sx={{ alignContent: "center" }}> */}
        {Boolean(isLoading) && <CircularProgress />}
        {Boolean(isAlert) && (
          <Alert
            variant="outlined"
            severity={alertSeverity}
            action={
              <IconButton aria-label="close" color="inherit" size="small" onClick={handleClose}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {message}
          </Alert>
        )}
        {/* </Box> */}
      </MDBox>
    </Dialog>
  );
};
export default LoadComponent;
