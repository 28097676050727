/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ERROR } from "constant/message";

export default function useArrayOperation() {
  const sortArray = (array, labelName) => {
    try {
      const result = array
        .map((i) => {
          const item = {
            ...i,
            ...{ label: i[labelName] },
          };
          return item;
        })
        .sort((a, b) => {
          // Ordonne le table par ordre alphabétique
          const nameA = a.label.toUpperCase(); // ignore upper and lowercase
          const nameB = b.label.toUpperCase(); // ignore upper and lowercase

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
      return result;
    } catch (error) {
      console.log("sortArray error");
      return ERROR;
    }
  };
  // Remove duplicated regions/ departement in array of lieu
  const removeDuplicateItem = (array, labelName) => {
    try {
      const dataArr = array.map((i) => {
        const item = {
          ...i,
          ...{ label: i[labelName] },
        };
        return item;
      });
      const uniqueMap = new Map();

      // Filter the array, keeping only the first occurrence of each item
      const newArray = dataArr.reduce((accumulator, current) => {
        // Use some identifier, like 'name' property, to determine uniqueness
        let identifier = "";
        identifier = current.label; // Change this based on your requirement

        // If the identifier is not already in the Map or object, add it and push the item to the accumulator
        if (!uniqueMap.has(identifier)) {
          uniqueMap.set(identifier, true);
          // Or, if using an object: uniqueItems[identifier] = true;
          accumulator.push(current);
        }

        return accumulator;
      }, []);
      const result = sortArray(newArray, "label");

      return result;
    } catch (error) {
      console.error("removeDuplicateItem error", error);
      return error;
    }
  };
  /* const customArrItem = (array, moduleName) => {
    try {
      const result = array.map((i) => {
        let newKeys = {};
        if (
          moduleName === MODULE_FERME ||
          moduleName === MODULE_CAMPAGNE ||
          moduleName === MODULE_LOT
        ) {
          newKeys = { isAnimal: true };
        } else if (moduleName === MODULE_USER) {
          newKeys = { isRole: true, isReset: true, isUser: true };
        }
        const item = {
          ...i,
          ...newKeys,
        };
        return item;
      });
      return result;
    } catch (error) {
      console.log("customArrItem error", error);
      return ERROR;
    }
  }; */
  return { removeDuplicateItem, sortArray };
}
