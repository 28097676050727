/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useMemo, useState } from "react";

import servicesAPI from "./../services/servicesAPI";
import LoadComponent from "components/LoadComponent";
import useDataTable from "hooks/useDataTable";
import TableComponent from "layouts/gestionUser/tableComponent";
import useForm from "hooks/useForm";
import useArrayOperation from "hooks/useArrayOperation";
import LayoutContext from "context/LayoutContext";
import useUser from "layouts/gestionUser/hooks/useUser";
import { ERROR } from "constant/message";
import { RESET_ACTION } from "./../../constant/dataTableActionConstant";
import MDTypography from "./../../components/MDTypography/index";
import COLORS from "./../../constant/colors";
import { normalizeText } from "utils/textOperation";

export default function IndexUser(): any {
  // const DataContext = createContext();
  // const navigate = useNavigate();
  const { isAlert, isLoading, message, alertSeverity, handleLoad, postData, getAll } =
    servicesAPI();
  const { columns, rows, renderDataTable, selectedItem, actionOption, onSelectedRow } =
    useDataTable();
  const {
    isOpenForm,
    handleFormState,
    valuesForm,
    errorForm,
    valuesFormInitialization,
    handleChangeFormValues,
    titleForm,
    validateForm,
  } = useForm();
  const { formConfigUser } = useUser();
  const { sortArray } = useArrayOperation();
  const [formConfig, setFormConfig] = useState([]);
  const [listData, setListData] = useState([]);

  // Récupération de la liste des données à afficher dans le tableau en fonction du module
  const getAllData = (): void => {
    try {
      const endpoint: string = "auth/users";
      getAll(endpoint).then((res: any) => {
        if (Array.isArray(res)) {
          const arrData = res
            .filter((i) => i.email !== "MED@clic" && i.email !== "User@clic")
            .map((i) => {
              const item = {
                ...i,
                ...{ isReset: true, userName: `${i.prenom} ${i.nom}` },
              };
              return item;
            });
          // console.log("ddd", arrData);
          const result = sortArray(arrData, "prenom");
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setListData(result);
          renderDataTable(result, "User");
        }
      });
    } catch (error) {
      console.log("getAllData error", error);
    }
  };

  // définition du formulaire du
  const setForm = async (): Promise<void> => {
    try {
      const form: [] = await formConfigUser();
      console.log("setFermeForm", form);
      setFormConfig(form);
      valuesFormInitialization(form);
      handleFormState(true);
    } catch (error) {
      console.error("setFermeForm error", error);
    }
  };
  const handleDialogForm = async (state: boolean): Promise<void> => {
    if (state) {
      // console.log("handleDialogForm", state);
      await setForm();
    } else {
      handleFormState(false);
      valuesFormInitialization(formConfig);
    }
  };
  const saveData = (): void => {
    try {
      const endpoint = "auth/register";
      // console.log("new data", valuesForm);
      postData(endpoint, valuesForm).then((res: any) => {
        if (res !== ERROR) {
          setTimeout(() => {
            void handleDialogForm(false);
            getAllData();
          }, 200);
        }
      });
    } catch (error) {
      console.log("saveData error", error);
    }
  };
  const resetPassword = (): void => {
    try {
      const endpoint = "Users/reset-password";
      const data = { email: selectedItem.email, passWord: "123456" };
      // console.log("new data", valuesForm);
      postData(endpoint, data).then((res: any) => {
        if (res !== ERROR) {
          setTimeout(() => {
            // void handleDialogForm(false);
            const loadData = {
              isAlert: true,
              message: `Mot de passe de l'utlisateur ${selectedItem.email} réinitialisé avec succès`,
              isLoading: false,
              alertSeverity: "success",
            };
            handleLoad(loadData);
            // console.log("fff", loadData);
            setTimeout(() => {
              onSelectedRow({}, "");
              getAllData();
            }, 200);
          }, 200);
        } else {
          onSelectedRow({}, "");
        }
      });
    } catch (error) {
      console.log("saveData error", error);
    }
  };
  useEffect(() => {
    getAllData();
  }, []);
  useEffect(() => {
    // console.log("useffect", selectedItem.email, actionOption);
    if (JSON.stringify(selectedItem) !== "{}") {
      if (actionOption === RESET_ACTION) {
        resetPassword();
      }
    }
  }, [selectedItem]);
  /* const handleSearch = (value: string): void => {
    // console.log("dddd", value);
    if (value === "") {
      renderDataTable(listData, "User");
    } else {
      const filteredData = listData.filter(
        (item: any) =>
          item?.prenom?.toLowerCase().includes(value.toLowerCase()) ||
          item?.nom?.toLowerCase().includes(value.toLowerCase()) ||
          item?.email?.toLowerCase().includes(value.toLowerCase()) ||
          item?.roles?.some((i) => i.roleName?.toLowerCase().includes(value.toLowerCase()))
      );
      if (filteredData.length > 0) {
        renderDataTable(filteredData, "User");
      } else {
        const loadData = {
          isAlert: true,
          message: "Aucune donnée",
          isLoading: false,
          alertSeverity: "error",
        };
        handleLoad(loadData);
      }
    }
  }; */

  const handleSearch = (value: string): void => {
    if (value === "") {
      renderDataTable(listData);
    } else {
      const searchTerm = normalizeText(value);

      const filteredData = listData.filter((item: any) => {
        return (
          normalizeText(String(item?.prenom)).includes(searchTerm) ||
          normalizeText(String(item?.nom)).includes(searchTerm) ||
          normalizeText(String(item?.role)).includes(searchTerm)
        );
      });
      if (filteredData.length > 0) {
        renderDataTable(filteredData);
      } else {
        const loadData = {
          isAlert: true,
          message: "Aucune donnée",
          isLoading: false,
          alertSeverity: "error",
        };
        handleLoad(loadData);
      }
    }
  };
  const contextValue = useMemo(() => {
    const value = {
      columns,
      rows,
      saveData,
      isOpenForm,
      // handleFormState,
      handleDialogForm,
      formConfig,
      valuesForm,
      errorForm,
      handleChangeFormValues,
      titleForm,
      validateForm,
      selectedItem,
      actionOption,
      onSelectedRow,
      handleSearch,
      refreshData: getAllData,
    };
    return value;
  }, [columns, rows, isOpenForm, formConfig, valuesForm, errorForm, selectedItem, actionOption]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDTypography
        display="block"
        variant="H1"
        fontWeight="medium"
        align="center"
        style={{ color: COLORS.primary }}
      >
        Liste des Utilisateurs
      </MDTypography>
      <LayoutContext.Provider value={contextValue}>
        <TableComponent />
      </LayoutContext.Provider>
      <LoadComponent
        isAlert={isAlert}
        isLoading={isLoading}
        message={message}
        alertSeverity={alertSeverity}
        handleLoad={handleLoad}
      />
    </DashboardLayout>
  );
}
// export default IndexUser;
