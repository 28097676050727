/* eslint-disable @typescript-eslint/no-dynamic-delete */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { SUCCESS, EMPTY_VALUE } from "constant/message";

import { useState } from "react";
import useUser from "layouts/gestionUser/hooks/useUser";

function useForm() {
  const { validateFormUser } = useUser();

  const [isOpenForm, setisOpenForm] = useState(false);
  const [errorForm, setErrorForm] = useState({});
  const [valuesForm, setValuesForm] = useState({});
  const [titleForm, setTitleForm] = useState("");
  const handleFormState = (state) => {
    setisOpenForm(state);
  };
  const valuesFormInitialization = (formConfig) => {
    // console.log("start");
    try {
      const initialValues = {};
      // const initialErrors = {};
      formConfig.forEach((config) => {
        initialValues[config.name] = config.initialValue || "";
        // initialErrors[config.name] = "";
      });
      setErrorForm({});
      setValuesForm(initialValues);
      setTitleForm("Ajout");
    } catch (error) {
      console.log("valuesFormInitialization error", error);
    }
  };

  const handleChangeFormValues = (event, moduleName) => {
    try {
      const { name, value } = event.target;
      let data = {
        ...valuesForm,
        [name]: value,
      };
      if (name === "roleUser") {
        data = {
          ...data,
          role: value.label,
        };
      }

      setValuesForm(data);
      // setErrorForm({});
      // console.log("handleChangeFormValues", name, value);
    } catch (error) {
      console.log("handleChangeFormValues error", error);
      alert(error);
    }
  };
  const validateForm = (formConfig) => {
    let data = "";
    try {
      const values = {};
      formConfig.forEach((config, idx, arr) => {
        if (config.isVisible) {
          let checkValue = false;
          // if (moduleName === MODULE_USER) {
          checkValue = validateFormUser(config.name, valuesForm[config.name]);
          // }
          // console.log("checkValue", config.name, checkValue);
          if (checkValue) {
            if (config.name in values) {
              delete values[config.name];
            }
          } else {
            values[config.name] = EMPTY_VALUE;
            // console.log("checkValue", values);
          }
        }
        if (idx === arr.length - 1) {
          // console.log("innnn", values);
          // handleAction();
          if (Object.keys(values).length === 0) {
            data = SUCCESS;
          }
        }
      });
      setErrorForm(values);
    } catch (error) {
      console.log("validateForm error", error);
    }
    return data;
  };
  return {
    handleFormState,
    isOpenForm,
    valuesFormInitialization,
    handleChangeFormValues,
    errorForm,
    valuesForm,
    setValuesForm,
    setErrorForm,
    validateForm,
    titleForm,
  };
}

export default useForm;
