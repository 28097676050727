/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useState } from "react";
// import DataTableAction from "components/DataTableRow/dataTableAction";
import useUser from "layouts/gestionUser/hooks/useUser";

// hooks to render datatable
export default function useDataTable() {
  const { tableColumnsUser, tableRowsUser } = useUser();

  const [tableRows, setTableRows] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [actionOption, setActionOption] = useState("");

  // Fonction permettant de récupérer la ligne sélectionnée dans le tableau
  const onSelectedRow = (item, action) => {
    console.log("onSelectedRow", item, action);
    setSelectedItem(item);
    setActionOption(action);
  };
  // organisation des données dans le datatable
  const renderDataTable = (listData) => {
    const arrData = [];
    if (listData.length > 0) {
      // const columnsActions = [{ Header: "action", accessor: "action", align: "center" }];
      // if (module === MODULE_USER) {
      // setTableColumns([...tableColumnsUser, ...columnsActions]);
      setTableColumns(tableColumnsUser);
      // }
      listData.map((i, idx, arr) => {
        let item = {};
        // if (module === MODULE_USER) {
        item = tableRowsUser(i, onSelectedRow);
        // }
        /* arrData.push({
          ...item,
          ...{ action: <DataTableAction handleAction={onSelectedRow} item={i} /> },
        }); */
        arrData.push(item);
        if (idx === arr.length - 1) {
          setTableRows(arrData);
          return "success";
        }
        return "over";
      });
    } else {
      setTableRows(arrData);
      setTableColumns([]);
    }
  };
  /* useEffect(() => {
    try {
    } catch (error) {
      console.log("useDataTable error", error);
    }
  }, [selectedItem]); */
  return {
    columns: tableColumns,
    rows: tableRows,
    renderDataTable,
    selectedItem,
    actionOption,
    onSelectedRow,
  };
}
