/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import {
  INPUT_COMPONENT,
  AUTO_COMPLETE_COMPONENT,
  DATE_COMPONENT,
  MULTI_SELECT_COMPONENT,
  SWITCH_COMPONENT,
  PHONE_INPUT_COMPONENT,
} from "constant/formComponentName";
import LayoutContext from "context/LayoutContext";
import MDInput from "components/MDInput";
import { SUCCESS } from "constant/message";

import DatePickerComponent from "components/DatePicker";
import PhoneInput from "react-phone-number-input/input";

/* function formComponent({
  formConfig,
  open,
  title,
  handleClose,
  valuesForm,
  errorForm,
  handleChangeFormValues,
}) { */
function formComponent() {
  const layoutContext = useContext(LayoutContext);
  const {
    formConfig,
    isOpenForm,
    handleDialogForm,
    valuesForm,
    errorForm,
    titleForm,
    handleChangeFormValues,
    validateForm,
    saveData,
    module,
  } = layoutContext;
  // console.log("valuesForm", formConfig, isOpenForm, titleForm);
  // console.log("valuesForm", valuesForm);
  // console.log("errorForm", errorForm);
  const onCloseDialog = () => {
    handleDialogForm(false);
    // console.log("close");
  };
  const onSubmit = () => {
    const result = validateForm(formConfig, module);
    if (result === SUCCESS) {
      saveData();
    }
    // console.log("onSubmit", result);
  };

  return (
    <Dialog
      open={isOpenForm}
      onClose={onCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">{titleForm}</DialogTitle>
      <DialogContent style={{ height: "500px" }}>
        <Card>
          <Box pt={4} pb={3} px={3}>
            <Box component="form" role="form">
              {formConfig.map((i) => (
                <Box mb={2} key={i.name}>
                  {i.isVisible && (
                    <>
                      {i.component === INPUT_COMPONENT && (
                        <MDInput
                          type="text"
                          label={i.label}
                          name={i.name}
                          fullWidth
                          onChange={(event) => handleChangeFormValues(event, module)}
                          // onChange={handleChangeFormValues}
                          error={!!errorForm[i.name]}
                          helperText={errorForm[i.name] && errorForm[i.name]}
                          // required
                          defaultValue={valuesForm[i.name] ? valuesForm[i.name] : ""}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              onSubmit();
                            }
                          }}
                        />
                      )}
                      {i.component === AUTO_COMPLETE_COMPONENT && (
                        <div>
                          {i.list.length > 0 ? (
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={i.list}
                              name={i.name}
                              value={valuesForm[i.name] ? valuesForm[i.name] : ""}
                              // onChange={handleChangeFormValues}
                              onChange={(event, newValue) => {
                                const data = {
                                  target: {
                                    name: i.name,
                                    value: newValue,
                                  },
                                };
                                handleChangeFormValues(data, module);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={i.label}
                                  onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                      onSubmit();
                                    }
                                  }}
                                />
                              )}
                            />
                          ) : (
                            <p>Aucune liste de {i.name} fournie</p>
                          )}
                          {errorForm[i.name] && (
                            <FormHelperText error>{errorForm[i.name]}</FormHelperText>
                          )}
                        </div>
                      )}
                      {i.component === DATE_COMPONENT && (
                        <DatePickerComponent
                          label={i.label}
                          name={i.name}
                          module={module}
                          dateValue={valuesForm[i.name] ? valuesForm[i.name] : new Date()}
                          handleDate={handleChangeFormValues}
                          isError={errorForm[i.name] && errorForm[i.name]}
                          error={errorForm[i.name] && errorForm[i.name]}
                        />
                      )}
                      {i.component === MULTI_SELECT_COMPONENT && (
                        <Box>
                          {i.list.length > 0 ? (
                            <FormControl sx={{ m: 1, minWidth: "100%" }}>
                              <InputLabel id="multiple-select-label">{i.label}</InputLabel>
                              <Select
                                multiple
                                value={valuesForm[i.name] ? valuesForm[i.name] : []}
                                onChange={(event) => handleChangeFormValues(event, module)}
                                name={i.name}
                                input={<Input />}
                                renderValue={(selected) => (
                                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    {selected.map((value) => (
                                      <Chip
                                        key={value.id}
                                        label={value.label}
                                        style={{ margin: 2 }}
                                      />
                                    ))}
                                  </div>
                                )}
                                sx={{
                                  "& .MuiSelect-select": {
                                    minWidth: "200px", // Adjust width of the select input
                                    paddingRight: "30px", // Add padding to accommodate the dropdown arrow
                                  },
                                }}
                              >
                                {i.list.map((x) => (
                                  <MenuItem value={x} key={x}>
                                    {x.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ) : (
                            <p>Aucune liste de {i.name} fournie</p>
                          )}
                        </Box>
                      )}
                      {i.component === SWITCH_COMPONENT && (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={valuesForm[i.name] ? valuesForm[i.name] : false}
                              onChange={(event) => {
                                const data = {
                                  target: {
                                    name: i.name,
                                    value: event.target.checked,
                                  },
                                };
                                handleChangeFormValues(data, module);
                              }}
                            />
                          }
                          label={i.label}
                          labelPlacement="top"
                        />
                      )}
                      {i.component === PHONE_INPUT_COMPONENT && (
                        <TextField
                          variant="outlined"
                          margin="normal"
                          // required
                          fullWidth
                          id="telephone"
                          label={i.label}
                          name={i.name}
                          autoComplete="telephone"
                          error={!!errorForm[i.name]}
                          helperText={errorForm[i.name] && errorForm[i.name]}
                          InputProps={{
                            inputComponent: PhoneInput,
                            inputProps: {
                              value: `${valuesForm[i.name] ? valuesForm[i.name] : ""}`,
                              onChange: (event) => {
                                const data = {
                                  target: {
                                    name: i.name,
                                    value: event,
                                  },
                                };
                                handleChangeFormValues(data, module);
                              },
                              placeholder: "Saisir le numéro de téléphone",
                              defaultCountry: "MR",
                              maxLength: 11,
                            },
                          }}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              onSubmit();
                            }
                          }}
                        />
                      )}
                    </>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseDialog}>Annuler</Button>
        <Button onClick={onSubmit} autoFocus>
          {titleForm === "Ajout" ? "Enregistrer" : "Modifier"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

formComponent.propTypes = {
  formConfig: PropTypes.object,
  open: PropTypes.bool,
  title: PropTypes.string,
  handleClose: PropTypes.func,
  valuesForm: PropTypes.object,
  errorForm: PropTypes.object,
  handleChangeFormValues: PropTypes.func,
};
formComponent.defaultProps = {
  formConfig: [],
  open: false,
  title: "",
  handleClose: () => {},
  valuesForm: {},
  errorForm: {},
  handleChangeFormValues: () => {},
};
// Memoized component
const Form = React.memo(formComponent);
export default Form;
