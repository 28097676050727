/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import { FormHelperText } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MDTypography from "components/MDTypography";
import "./Calendar.css";
// import { makeStyles } from "@mui/styles";

function Index_DatePicker({ date_value, label, handleDate, isError, error }) {
  // function Index_DatePicker({ label }) {
  // const [date_value, set_date_value] = useState(new Date());
  const handleChange = (value) => {
    // set_date_value(value);
    handleDate(value);
  };

  return (
    <div /* style={{ marginLeft: 0, marginRight: 0, width: 480 }} */>
      <MDTypography display="block" variant="button" fontWeight="regular">
        {label}
      </MDTypography>
      <DatePicker
        selected={date_value}
        onChange={(date) => handleChange(date)}
        // classes={{ root: classes.datePickerStyles }}
        dateFormat="dd/MM/yyyy"
        // className={{}}
        calendarClassName="custom-calendar"
        wrapperClassName="datePicker full-width"
      />
      {isError && <FormHelperText>{error}</FormHelperText>}
    </div>
  );
}

export default Index_DatePicker;
Index_DatePicker.propTypes = {
  label: PropTypes.string,
  // value: PropTypes.string,
  date_value: PropTypes.instanceOf(Date),
  handleDate: PropTypes.func,
  isError: PropTypes.bool,
  error: PropTypes.string,
};
Index_DatePicker.defaultProps = {
  label: "",
  date_value: new Date(),
  // value: "",
  handleDate: () => {},
  isError: false,
  error: "",
};
