/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import * as React from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";

export default function AlertContent({ color, msg }) {
  return (
    <MDBox pt={2} px={2}>
      <MDAlert color={color} dismissible>
        <MDTypography variant="body2" color="white">
          {msg}
        </MDTypography>
      </MDAlert>
    </MDBox>
  );
}
AlertContent.propTypes = {
  msg: PropTypes.string,
  color: PropTypes.string,
};
AlertContent.defaultProps = {
  msg: "",
  color: "",
};
