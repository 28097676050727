/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
// import EditIcon from "@mui/icons-material/Edit";
import EngineeringIcon from "@mui/icons-material/Engineering";
import LockResetIcon from "@mui/icons-material/LockReset";
// import InfoIcon from "@mui/icons-material/Info";
import { Box /*, Button */, Switch } from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";
// import cow from "assets/images/cow.png";
import {
  // ANIMAL_ACTION,
  ACTION_ROLE,
  ETAT_ACTION,
  RESET_ACTION,
} from "constant/dataTableActionConstant";

export default function DataTableAction({ handleAction, item }) {
  const theme = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            color: `${item.enabled ? "green" : "red"}`,
          },
          colorPrimary: {
            "&.Mui-checked": {
              color: item.etatColor,
            },
          },
          track: {
            opacity: 0.5,
            ".Mui-checked.Mui-checked + &": {
              opacity: 0.7,
              backgroundColor: `${item.enabled ? "green" : "red"}`,
            },
          },
        },
      },
    },
  });
  return (
    <Box textAlign="center" sx={{ display: "flex", flexDirection: "row" }}>
      {/* <IconButton onClick={() => handleAction(item, "edit")} size="small">
        <EditIcon />
      </IconButton> */}
      {/* <IconButton onClick={() => handleAction(item, "info")} size="small">
        <InfoIcon />
      </IconButton> */}
      {item.isRole && (
        <IconButton onClick={() => handleAction(item, ACTION_ROLE)} size="small">
          <EngineeringIcon />
        </IconButton>
      )}
      {item.isReset && (
        <IconButton onClick={() => handleAction(item, RESET_ACTION)} size="small">
          <LockResetIcon />
        </IconButton>
      )}
      {item.isUser && (
        <Box>
          <ThemeProvider theme={theme}>
            <Switch
              checked={item.enabled}
              size="small"
              onChange={() => handleAction(item, ETAT_ACTION)}
              disabled={!item.etat}
            />
          </ThemeProvider>
        </Box>
      )}
      {/* item.isAnimal && (
        <Button onClick={() => handleAction(item, ANIMAL_ACTION)}>
          <img src={cow} alt="animals" width="20px" />
        </Button>
      ) */}
    </Box>
  );
}
DataTableAction.propTypes = {
  handleAction: PropTypes.func,
  item: PropTypes.object,
};
DataTableAction.defaultProps = {
  handleAction: () => {},
  item: {},
};
