/* export const normalizeText = (text: string): string => {
  return (
    text
      ?.normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase() || ""
  );
}; */
export const normalizeText = (text: string | null | undefined): string => {
  if (text == null) return ""; // Vérifie si text est null, undefined ou vide
  return text
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "") // Enlève les accents
    .toLowerCase(); // Convertit en minuscule
};

/* export const tableCellTextFormat = (text, length) => {
  return text?.length >= length ? `${text.substring(0, length)}...` : text || "";
}; */
export const tableCellTextFormat = (text: string | null | undefined, length: number): string => {
  if (text == null) return ""; // Vérifie si text est null, undefined ou une chaîne vide
  return text.length >= length ? `${text.substring(0, length)}...` : text;
};
