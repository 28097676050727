export const USER_DATA_STORAGE = "user-tokenBCMPSA";
export const LAST_PATH: string = "lastPathBCMPSA";
export const MONTH_NAMES = [
  "Janv",
  "Fev",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juilet",
  "Aout",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
