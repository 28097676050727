/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */

const link = {
  defaultProps: {
    underline: "none",
    color: "inherit",
  },
};

export default link;
