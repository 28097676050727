/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */

import React from "react";
import Grid from "@mui/material/Grid";

function Error404() {
  return (
    <Grid
      container
      spacing={0}
      // style={{ backgroundColor: "red" }}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={8}>
        <h1>Page Not Found</h1>
      </Grid>
    </Grid>
  );
}
export default Error404;
