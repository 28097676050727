import { ERROR } from "constant/message";
import { USER_DATA_STORAGE } from "constant";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const userInfos = () => {
  try {
    const userDataStorage = localStorage.getItem(USER_DATA_STORAGE);
    let userInfo = {};
    if (userDataStorage !== null) {
      userInfo = JSON.parse(userDataStorage);
    }
    return userInfo;
  } catch (error) {
    console.log("userInfos eror", error);
    return ERROR;
  }
};
export default userInfos;
/* export const isSuperAdmin = (): void => {
  return userInfos().roles.some((i) => i.roleName === SUPER_ADMIN_ROLE);
}; */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const isAdmin = () => {
  // return userInfos().roles.some((i) => i.roleName === "ADMIN");
  return userInfos().role === "ADMIN";
};
