import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import RubriqueCard from "layouts/gestion_sante_animale/component/RubriqueCard";
import globalStyles from "style";
import { Grid, IconButton } from "@mui/material";
import SearchBar from "layouts/gestion_sante_animale/component/SearchBar";

import MDTypography from "components/MDTypography";
import ServicesAPI from "./../../services/servicesAPI";
import { type RubriqueSanteAnimalType } from "constant/Types";
import RubriqueDialogAction from "layouts/gestion_sante_animale/component/RubriqueDialogAction";
import PDFViewerDialog from "layouts/gestion_sante_animale/component/PDFViewerDialog";
import LoadComponent from "components/LoadComponent/index";
// import COLORS from "../../../assets/theme/base/colors.js"
// import colors from "assets/theme/base/colors";
import { LAST_PATH } from "constant";
import {
  PATH_MEDICAMENT,
  ENDPOINT_GET_MEDICAMENT,
  MODULE_MEDICAMENT,
  TITLE_MEDICAMENT,
  // PATH_TRAITEMENT,
  // MODULE_TRAITEMENT,
  // TITLE_TRAITEMENT,
  MODULE_MALADIE,
  PATH_MALADIE_ZOONOTIQUE,
  ENDPOINT_GET_MALADIE_ZOONOTIQUE,
  MODULE_MALADIE_ZOONOTIQUE,
  TITLE_MALADIE_ZOONOTIQUE,
  PATH_MALADIE_NON_ZOONOTIQUE,
  ENDPOINT_GET_MALADIE_NON_ZOONOTIQUE,
  MODULE_MALADIE_NON_ZOONOTIQUE,
  TITLE_MALADIE_NON_ZOONOTIQUE,
  PATH_VACCIN,
  MODULE_VACCIN,
  TITLE_VACCIN,
  ENDPOINT_GET_VACCIN,
  PATH_DIAGNOSTIC,
  MODULE_DIAGNOSTIC,
  ENDPOINT_GET_DIAGNOSTIC,
  ENDPOINT_GET_DIAGNOSTIC_MALADIE,
  TITLE_DIAGNOSTIC,
  PATH_ANNEXE,
  TITLE_ANNEXE,
  ENDPOINT_GET_ANNEXE,
  MODULE_ANNEXE,
  PATH_TECHNIQUE,
  TITLE_TECHNIQUE,
  ENDPOINT_GET_TECHNIQUE,
  MODULE_TECHNIQUE,
  PATH_MPSA,
  PATH_MALADIE_NON_OBLIGATOIRE,
  TITLE_MALADIE_NON_OBLIGATOIRE,
  PATH_EXAMEN,
  TITLE_EXAMEN,
  TITLE_PRELEVEMENT,
  MODULE_PRELEVEMENT,
  PATH_PRELEVEMENT,
  TITLE_TECHNIQUE_VETERINAIRE,
  MODULE_TECHNIQUE_VETERINAIRE,
  PATH_TECHNIQUE_VETERINAIRE,
} from "constant/moduleInfo";
import MDBox from "components/MDBox";

import SyncIcon from "@mui/icons-material/Sync";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { isAdmin } from "utils/userInfos";
import COLORS from "constant/colors";

export default function IndexRubrique(): any {
  const location = useLocation();
  const servicesAPI = ServicesAPI();
  const navigate = useNavigate();
  const { isAlert, isLoading, message, alertSeverity, handleLoad } = servicesAPI;
  const defaultSelectedItem = {
    id: 0,
    title: "",
    description: "",
    nom: "",
    declaration: "",
    numAuth: "",
  };
  const [listData, setListData] = useState<RubriqueSanteAnimalType[]>([]);
  const [listFilterData, setlistFilterData] = useState<RubriqueSanteAnimalType[]>([]);
  const [listMaladieDiagnostic, setListMaladieDiagnostic] = useState([]);
  const [selectedItem, setSelectedItem] = useState<RubriqueSanteAnimalType>(defaultSelectedItem);

  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [showName, setShowName] = useState("");
  const [openDialogAction, setOpenDialogAction] = useState(false);
  const [openPDFDialog, setOpenPDFDialog] = useState(false);
  const [pdfURL, setPdfURL] = useState("");
  const [pdfDiagnosticMaladieURL, setPdfDiagnosticMaladieURL] = useState("");

  const isAdminRole: boolean = isAdmin();
  // Récupère la liste des données et les range dans un tableau
  const getAllData = (endpoint: string): void => {
    try {
      servicesAPI.getAll(endpoint).then((res: any) => {
        if (Array.isArray(res)) {
          // Ordonne le table par ordre alphabétique
          const responseResult: RubriqueSanteAnimalType[] = res
            .map((i) => {
              let description: string = "";
              let title: string = "";
              let nom: string = "";
              if (endpoint.includes("Vaccin")) {
                description = i.typeVaccin;
                title = `${i.nomCommerce}`;
                nom = `${i.nomCommerce}`;
              } else {
                title = i.nom;
                nom = i.nom;
              }
              // console.log("ddd", i);
              const item = {
                ...i,
                ...{ title, description, nom },
              };
              return item;
            })
            .sort((a, b) => {
              const nameA = a.title.toUpperCase(); // ignore upper and lowercase
              const nameB = b.title.toUpperCase(); // ignore upper and lowercase

              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }

              // names must be equal
              return 0;
            });
          let result = responseResult;
          if (location.pathname === PATH_MALADIE_NON_OBLIGATOIRE) {
            result = responseResult.filter((i) => i.declaration === "NON");
          } else if (location.pathname === PATH_MALADIE_ZOONOTIQUE) {
            result = responseResult.filter((i) => i.declaration === "OUI");
          } else if (location.pathname === PATH_TECHNIQUE) {
            const order = [
              "Mesurer la température corporelle",
              "Évaluer le poids",
              "Désinfecter avant injection",
              "Nettoyer les plaies",
              "Administrer des médicaments",
            ];
            result = responseResult.sort((a, b) => {
              return order.indexOf(a.nom) - order.indexOf(b.nom);
            });
          } else if (location.pathname === PATH_MEDICAMENT) {
            const order = [
              "Antibiotiques",
              "Antiparasitaires",
              "Anti-inflammatoires",
              "Autres produits",
              "Pour les volailles",
            ];
            const dataFilter = responseResult.filter((i) => i.numAuth === null);
            result = dataFilter.sort((a, b) => {
              return order.indexOf(a.nom) - order.indexOf(b.nom);
            });
          } else if (location.pathname === PATH_EXAMEN) {
            const order = [
              "Objectifs",
              "Méthode en trois étapes",
              "Examen clinique rapproché",
              "Tableau récapitulatif",
            ];
            const dataFilter = responseResult.filter((i) => i.numAuth === "exam");
            result = dataFilter.sort((a, b) => {
              return order.indexOf(a.nom) - order.indexOf(b.nom);
            });
          } else if (location.pathname === PATH_TECHNIQUE_VETERINAIRE) {
            const order = [
              "Techniques d’autopsie sur petits ruminants",
              "Techniques d’autopsie sur les volailles",
              "Techniques de contention",
            ];
            const dataFilter = responseResult.filter(
              (i) => i.numAuth === "techniques_veterinaires"
            );
            result = dataFilter.sort((a, b) => {
              return order.indexOf(a.nom) - order.indexOf(b.nom);
            });
          } else if (location.pathname === PATH_PRELEVEMENT) {
            const order = [
              "Prélèvements à partir de l’animal vivant",
              "Modalités de conservation et de transport",
              "Confirmation de diagnostic",
            ];
            const dataFilter = responseResult.filter((i) => i.numAuth === "prelevement");
            result = dataFilter.sort((a, b) => {
              return order.indexOf(a.nom) - order.indexOf(b.nom);
            });
            // result = responseResult.filter((i) => i.numAuth === "prelevement");
          } else if (location.pathname === PATH_DIAGNOSTIC) {
            const order = [
              "Abattement",
              "Maladies de peau",
              "Boiterie",
              "Mort subite",
              "Avortement",
              "Maladies respiratoires (petits ruminants)",
            ];
            // const dataFilter = responseResult.filter((i) => i.numAuth === "prelevement");
            result = responseResult.sort((a, b) => {
              return order.indexOf(a.nom) - order.indexOf(b.nom);
            });
            // result = responseResult.filter((i) => i.numAuth === "prelevement");
          }
          setListData(result);
          setlistFilterData(result);
          // console.log("ddd", result);
        }
      });
    } catch (error) {
      console.log("get_all_data error", error, listData.length);
    }
  };
  const getAllMaladieByDiagnostic = (diagnostic: string): void => {
    try {
      // console.log("dddd", `${ENDPOINT_GET_DIAGNOSTIC_MALADIE}${diagnostic}`);
      servicesAPI.getAll(`${ENDPOINT_GET_DIAGNOSTIC_MALADIE}${diagnostic}`).then((res: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        if (Object.keys(res).length > 0 && Array.isArray(res.maladies)) {
          // console.log("dddd", res);
          const result = res.maladies
            .map((i) => {
              const item = {
                ...i,
                ...{ label: i.nom },
              };
              return item;
            })
            .sort((a, b) => {
              const nameA = a.label.toUpperCase(); // ignore upper and lowercase
              const nameB = b.label.toUpperCase(); // ignore upper and lowercase

              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }

              // names must be equal
              return 0;
            });

          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setListMaladieDiagnostic(result);
        }
      });
    } catch (error) {
      console.log("get_all_data error", error, listData.length);
    }
  };
  const getRubriqueInfos = (): void => {
    let titleRubrique: string = "";
    let endPointRubrique: string = "";
    let module: string = "";
    let typeRubrique: string = "";
    if (location.pathname === PATH_MEDICAMENT) {
      titleRubrique = TITLE_MEDICAMENT;
      endPointRubrique = ENDPOINT_GET_MEDICAMENT;
      module = MODULE_MEDICAMENT;
      typeRubrique = MODULE_MEDICAMENT;
    } else if (location.pathname === PATH_VACCIN) {
      titleRubrique = TITLE_VACCIN;
      endPointRubrique = ENDPOINT_GET_VACCIN;
      module = MODULE_VACCIN;
      typeRubrique = MODULE_VACCIN;
    } else if (location.pathname === PATH_ANNEXE) {
      titleRubrique = TITLE_ANNEXE;
      endPointRubrique = ENDPOINT_GET_ANNEXE;
      module = MODULE_ANNEXE;
      typeRubrique = MODULE_ANNEXE;
    } else if (location.pathname === PATH_MALADIE_ZOONOTIQUE) {
      titleRubrique = TITLE_MALADIE_ZOONOTIQUE;
      endPointRubrique = ENDPOINT_GET_MALADIE_ZOONOTIQUE;
      module = MODULE_MALADIE_ZOONOTIQUE;
      typeRubrique = MODULE_MALADIE;
    } else if (location.pathname === PATH_MALADIE_NON_OBLIGATOIRE) {
      titleRubrique = TITLE_MALADIE_NON_OBLIGATOIRE;
      endPointRubrique = ENDPOINT_GET_MALADIE_ZOONOTIQUE;
      module = MODULE_MALADIE_ZOONOTIQUE;
      typeRubrique = MODULE_MALADIE;
    } else if (location.pathname === PATH_MALADIE_NON_ZOONOTIQUE) {
      titleRubrique = TITLE_MALADIE_NON_ZOONOTIQUE;
      endPointRubrique = ENDPOINT_GET_MALADIE_NON_ZOONOTIQUE;
      module = MODULE_MALADIE_NON_ZOONOTIQUE;
      typeRubrique = MODULE_MALADIE;
    } else if (location.pathname === PATH_DIAGNOSTIC) {
      titleRubrique = TITLE_DIAGNOSTIC;
      endPointRubrique = ENDPOINT_GET_DIAGNOSTIC;
      module = MODULE_DIAGNOSTIC;
      typeRubrique = MODULE_DIAGNOSTIC;
    } else if (location.pathname === PATH_TECHNIQUE) {
      titleRubrique = TITLE_TECHNIQUE;
      endPointRubrique = ENDPOINT_GET_TECHNIQUE;
      module = MODULE_TECHNIQUE;
      typeRubrique = MODULE_TECHNIQUE;
    } else if (location.pathname === PATH_EXAMEN) {
      titleRubrique = TITLE_EXAMEN;
      endPointRubrique = ENDPOINT_GET_MEDICAMENT;
      module = "Examen Clinique";
      typeRubrique = MODULE_MEDICAMENT;
    } else if (location.pathname === PATH_PRELEVEMENT) {
      titleRubrique = TITLE_PRELEVEMENT;
      endPointRubrique = ENDPOINT_GET_MEDICAMENT;
      module = MODULE_PRELEVEMENT;
      typeRubrique = MODULE_MEDICAMENT;
    } else if (location.pathname === PATH_TECHNIQUE_VETERINAIRE) {
      titleRubrique = TITLE_TECHNIQUE_VETERINAIRE;
      endPointRubrique = ENDPOINT_GET_MEDICAMENT;
      module = MODULE_TECHNIQUE_VETERINAIRE;
      typeRubrique = MODULE_MEDICAMENT;
    }
    if (endPointRubrique !== "") {
      setTitle(titleRubrique);
      getAllData(endPointRubrique);
      setShowName(module);
      setType(typeRubrique);
    } else {
      setTitle("");
      setShowName("");
      setType("");
      const loadData = {
        isAlert: true,
        message: "Module Indisponible",
        isLoading: false,
        alertSeverity: "error",
      };
      handleLoad(loadData);
    }
    // }
  };
  const initialization = (): void => {
    setListData([]);
    setlistFilterData([]);
    setListMaladieDiagnostic([]);
    setSelectedItem(defaultSelectedItem);
    getRubriqueInfos();
  };
  useEffect(() => {
    localStorage.setItem(String(LAST_PATH), location.pathname);
    initialization();
  }, [location.pathname]);
  // ouvre un modal (boite de dialogue) contenant les boutons upload et download
  const handleOpenDialogAction = (value: RubriqueSanteAnimalType): void => {
    setSelectedItem(value);

    if (location.pathname === PATH_DIAGNOSTIC) {
      getAllMaladieByDiagnostic(value.title);
    }
    if (isAdminRole || value.title === "Antiparasitaires") {
      setOpenDialogAction(true);
    } else {
      handleOpenFile(value.title, false);
    }
  };
  const handleCloseDialogAction = (): void => {
    setSelectedItem(defaultSelectedItem);
    setOpenDialogAction(false);
    setListMaladieDiagnostic([]);
    setPdfDiagnosticMaladieURL("");
  };
  // Récupère le fichier associer à l'élément sélectionné
  const handleOpenFile = (fileName: string, isDiagnosticbyMaladie: boolean): void => {
    try {
      // setPdfURL("");
      servicesAPI.downloadFile(fileName, type).then(async (res: any) => {
        if (res !== "error") {
          // Convert the PDF string to a Blob object
          // console.log("dddd res", res);

          const pdfBlob = new Blob([res], { type: "application/pdf" });
          // const pdfBlob = new Blob([res], { type: "text/html" });
          // Create a URL for the Blob object
          const pdfBlobURL = URL.createObjectURL(pdfBlob);
          // open url in new tab
          // setIsPdfViewer(true);
          // setPdfUrl(pdfBlobURL);
          if (isDiagnosticbyMaladie) {
            setPdfDiagnosticMaladieURL(pdfBlobURL);
          } else {
            setOpenDialogAction(false);
            setPdfURL(pdfBlobURL);
            setOpenPDFDialog(true);
            // }
          }
        }
      });
    } catch (error) {
      console.log("handleGetFile error", error);
    }
  };
  // Upload un fichier pour l'associer à l'élément sélectionné
  const handleUplaodFile = (file: Blob, fileName: string): void => {
    try {
      if (file !== null) {
        /*  let endpoint= ""
        if(location.pathname=== PATH_ANNEXE) {
          endpoint=ENDPOINT_UPLOAD_ANNEXE
        } else {
          endpoint=
        } */
        servicesAPI.uploadFile(file, fileName).then(async (res: any) => {
          // uploadFile(file, name).then(async (res) => {
          if (res !== "axios_error") {
            // refresh();
            setTimeout(() => {
              console.log("upload over");
              // handleCloseDialogAction();
              handleOpenFile(fileName, false);
            }, 200);
          }

          // console.log("here i am////", res, file.name, selectedItem.nom);
        });
      } else {
        alert("Veuillez choisir un fichier");
      }
    } catch (error) {
      console.log("handlePostFile error", error);
    }
  };
  const handleClosePDFDialog = (): void => {
    setOpenPDFDialog(false);
    setPdfURL("");
    setSelectedItem(defaultSelectedItem);
    setListMaladieDiagnostic([]);
    setPdfDiagnosticMaladieURL("");
  };
  const goBack = (): void => {
    //  console.log("dddd");
    navigate(PATH_MPSA, { state: { prevPath: location.pathname } });
  };
  const refresh = (): void => {
    initialization();
  };
  const gobalClasses = globalStyles();
  // console.log("rrr", listFilterData);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div>
        <MDTypography
          display="block"
          variant="H1"
          fontWeight="medium"
          align="center"
          style={{ color: COLORS.primary, marginBottom: 10 }}
        >
          {title}
        </MDTypography>
        <MDBox
          display="flex"
          alignItems="center"
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <IconButton onClick={goBack}>
            <ArrowBackIcon fontSize="small" style={{ color: "gray" }} />
          </IconButton>
          <IconButton onClick={refresh}>
            <SyncIcon fontSize="small" style={{ color: "gray" }} />
          </IconButton>
        </MDBox>
        <SearchBar dataArray={listData} setFilterArray={setlistFilterData} />
        <div
          className={gobalClasses.centerContent}
          style={{ marginTop: 15, marginBottom: 15, width: "100%" }}
        >
          <Grid container spacing={3} sx={{ justifyContent: "center", alignItems: "center" }}>
            {listFilterData.map((i) => (
              <Grid item xs={12} lg={4} key={i.id}>
                <RubriqueCard cardData={i} handleOpenDialogAction={handleOpenDialogAction} />
              </Grid>
            ))}
          </Grid>
        </div>
        {openDialogAction && (
          <RubriqueDialogAction
            selectedItem={selectedItem}
            handleOpenFile={handleOpenFile}
            handleUploadFile={handleUplaodFile}
            handleClose={handleCloseDialogAction}
            open={openDialogAction}
            showName={showName}
          />
        )}
        {openPDFDialog && (
          <PDFViewerDialog
            selectedItem={selectedItem}
            pdfURL={pdfURL}
            handleClose={handleClosePDFDialog}
            open={openPDFDialog}
            listMaladieDiagnostic={listMaladieDiagnostic}
            handleOpenMaladieFile={handleOpenFile}
            pdfDiagnosticMaladieURL={pdfDiagnosticMaladieURL}
            setPdfDiagnosticMaladieURL={setPdfDiagnosticMaladieURL}
          />
        )}
        <LoadComponent
          isAlert={isAlert}
          isLoading={isLoading}
          message={message}
          alertSeverity={alertSeverity}
          handleLoad={handleLoad}
        />
      </div>
    </DashboardLayout>
  );
}
