/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import imgMedicament from "assets/images/rubrique/santeAnimal/medicament.webp";
import imgMaladie from "assets/images/rubrique/santeAnimal/maladiePrioritaire.webp";
import imgMaladieNonZoonotique from "assets/images/rubrique/santeAnimal/maladieNonZoonotique.webp";
import imgMaladieZoonotique from "assets/images/rubrique/santeAnimal/maladieZoonotique.webp";
// import imgMaladieNonObligatoire from "assets/images/rubrique/santeAnimal/maladiePrioritaire.webp";
import imgVaccin from "assets/images/rubrique/santeAnimal/vaccin.webp";
import imgDiagnostic from "assets/images/rubrique/santeAnimal/diagnostic.webp";
import imgTraitement from "assets/images/rubrique/santeAnimal/traitement.webp";
import imgTechnique from "assets/images/rubrique/santeAnimal/technique.webp";
import imgExamen from "assets/images/rubrique/santeAnimal/examen.webp";
// import imgAnnexe from "assets/images/backlog.png";
import { Grid, IconButton } from "@mui/material";
import CustomCard from "layouts/gestion_sante_animale/component/CustomCard";
import globalStyles from "style";
import { useLocation, useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import {
  PATH_DIAGNOSTIC,
  PATH_MALADIE,
  PATH_MALADIE_NON_ZOONOTIQUE,
  PATH_MALADIE_ZOONOTIQUE,
  PATH_MEDICAMENT,
  PATH_MPSA,
  PATH_TRAITEMENT,
  TITLE_DIAGNOSTIC,
  TITLE_MALADIE,
  TITLE_MALADIE_NON_ZOONOTIQUE,
  TITLE_MEDICAMENT,
  TITLE_TRAITEMENT,
  TITLE_VACCIN,
  PATH_VACCIN,
  TITLE_MALADIE_ZOONOTIQUE,
  TITLE_MPSA,
  TITLE_TECHNIQUE,
  PATH_TECHNIQUE,
  PATH_MALADIE_NON_OBLIGATOIRE,
  TITLE_MALADIE_NON_OBLIGATOIRE,
  TITLE_EXAMEN,
  PATH_EXAMEN,
  TITLE_PRELEVEMENT,
  PATH_PRELEVEMENT,
  TITLE_TECHNIQUE_VETERINAIRE,
  PATH_TECHNIQUE_VETERINAIRE,
  // PATH_ANNEXE,
  // TITLE_ANNEXE,
} from "../../constant/moduleInfo";
import { LAST_PATH } from "constant";
import MDTypography from "components/MDTypography";
// import colors from "assets/theme/base/colors";
import COLORS from "constant/colors";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function Index_sante_animale() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();

  const [gridData, setGridData] = useState([]);
  const [title, setTitle] = useState("");
  /* Element à afficher sur le cardview */
  const mpsaCardData = [
    {
      title: TITLE_TECHNIQUE,
      img: imgTechnique,
      route: PATH_TECHNIQUE,
    },
    {
      title: TITLE_EXAMEN,
      img: imgExamen,
      route: PATH_EXAMEN,
    },
    {
      title: TITLE_MALADIE,
      img: imgMaladie,
      route: PATH_MALADIE,
    },

    {
      title: TITLE_DIAGNOSTIC,
      img: imgDiagnostic,
      route: PATH_DIAGNOSTIC,
    },
    {
      title: TITLE_PRELEVEMENT,
      img: imgExamen,
      route: PATH_PRELEVEMENT,
    },

    {
      title: TITLE_TRAITEMENT,
      img: imgTraitement,
      route: PATH_TRAITEMENT,
    },
    {
      title: TITLE_TECHNIQUE_VETERINAIRE,
      img: imgExamen,
      route: PATH_TECHNIQUE_VETERINAIRE,
    },
  ];
  const maladieCardData = [
    {
      title: TITLE_MALADIE_NON_ZOONOTIQUE,
      img: imgMaladieNonZoonotique,
      route: PATH_MALADIE_NON_ZOONOTIQUE,
    },
    {
      title: TITLE_MALADIE_ZOONOTIQUE,
      img: imgMaladieZoonotique,
      route: PATH_MALADIE_ZOONOTIQUE,
    },
    {
      title: TITLE_MALADIE_NON_OBLIGATOIRE,
      img: imgMaladie,
      route: PATH_MALADIE_NON_OBLIGATOIRE,
    },
  ];
  const traitementCardData = [
    {
      title: TITLE_MEDICAMENT,
      img: imgMedicament,
      route: PATH_MEDICAMENT,
    },
    { title: TITLE_VACCIN, img: imgVaccin, route: PATH_VACCIN },
    // { id: 3, title: TITLE_ANNEXE, img: imgAnnexe, route: PATH_ANNEXE },
  ];
  // Naviguer vers l'élément choisi
  const onChange_Component = (item) => {
    if (item.route !== undefined) {
      // navigate(item.route, { state: { title: item.title, endPointURL: item.endPointURL } });
      localStorage.setItem(String(LAST_PATH), item.route);
      navigate(item.route);
    } else {
      alert("Rubrique Indisponible");
    }
  };
  useEffect(() => {
    localStorage.setItem(String(LAST_PATH), location.pathname);
    let prevPath = "";
    if (state !== null) {
      prevPath = state.prevPath;
    }

    // console.log("fff location.pathname", prevPath, prevPath === PATH_MALADIE_NON_ZOONOTIQUE);
    if (
      prevPath === PATH_MALADIE_NON_ZOONOTIQUE ||
      prevPath === PATH_MALADIE_ZOONOTIQUE ||
      prevPath === PATH_MALADIE_NON_OBLIGATOIRE
    ) {
      setGridData(maladieCardData);
      setTitle(TITLE_MALADIE);
    } else if (prevPath === PATH_MEDICAMENT || prevPath === PATH_VACCIN) {
      setGridData(traitementCardData);
      setTitle(TITLE_TRAITEMENT);
    } else if (location.pathname === PATH_MPSA) {
      setGridData(mpsaCardData);
      setTitle(TITLE_MPSA);
      // console.log("mpsaCardData");
    } else if (location.pathname === PATH_TRAITEMENT) {
      setGridData(traitementCardData);
      setTitle(TITLE_TRAITEMENT);
    } else if (location.pathname === PATH_MALADIE) {
      setGridData(maladieCardData);
      setTitle(TITLE_MALADIE);
      // console.log("maladieCardData");
    }
  }, [location.pathname]);
  const goBack = () => {
    console.log("goBack");
    //
    // navigate(PATH_MPSA);
    setGridData(mpsaCardData);
    setTitle(TITLE_MPSA);
  };
  const gobalClasses = globalStyles();
  return (
    <DashboardLayout>
      <DashboardNavbar />

      {title !== TITLE_MPSA && (
        <>
          <MDTypography
            display="block"
            variant="H1"
            fontWeight="medium"
            align="center"
            style={{ color: COLORS.primary }}
          >
            {title}
          </MDTypography>

          <MDBox
            display="flex"
            alignItems="center"
            sx={{ justifyContent: "center", alignItems: "center" }}
          >
            <IconButton onClick={goBack}>
              <ArrowBackIcon fontSize="small" style={{ color: "gray" }} />
            </IconButton>
          </MDBox>
        </>
      )}
      <div className={gobalClasses.centerContent} style={{ margin: 15, with: "100%" }}>
        <Grid container spacing={3} sx={{ justifyContent: "center", alignItems: "center" }}>
          {gridData.map((i) => (
            <Grid item xs={12} lg={6} key={i.title}>
              <CustomCard cardData={i} handleShowDetails={onChange_Component} />
            </Grid>
          ))}
        </Grid>
      </div>
    </DashboardLayout>
  );
}

export default Index_sante_animale;
