/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import routesData from "router/routes";
import { LAST_PATH } from "./../../../constant";

function Logout() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    // console.log("hhh");
    setOpen(false);
    localStorage.setItem(String(LAST_PATH), routesData[0].route);
    setTimeout(() => {
      console.log("i'm not logging out", routesData[0].route);
      navigate(routesData[0].route);
    }, 500);
  };
  const sign_out = () => {
    localStorage.clear();
    // localStorage.setItem("user-token", "");
    setTimeout(() => {
      console.log("i'm logging out");
      navigate("/");
    }, 500);
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Déconnexion</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Souhaitez vous vous déconnecter?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Non</Button>
          <Button onClick={sign_out} autoFocus>
            Oui
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default Logout;
