import React from "react";
import PropTypes from "prop-types";
import { Box, IconButton, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";
import { type RubriqueSanteAnimalType } from "constant/Types";
// import colors from "assets/theme/base/colors";
import COLORS from "constant/colors";
import { normalizeText } from "utils/textOperation";

const useStyles = makeStyles(() => ({
  textField: {
    width: "300px", // Adjust the width as needed
    "& .MuiInputBase-input": {
      color: COLORS.primary, // Change text color
      fontSize: "16px", // Change font size
    },
    "& label.Mui-focused": {
      color: COLORS.primary, // Change label color when focused
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: COLORS.primary, // Change outline color when focused
      },
    },
  },
}));

function SearchBar({ dataArray, setFilterArray }): JSX.Element {
  const classes = useStyles();
  const filterData = (query: string, arr: RubriqueSanteAnimalType[]): RubriqueSanteAnimalType[] => {
    if (query === "") {
      return arr;
    }
    return arr.filter((d) => normalizeText(d.title).includes(normalizeText(query)));
  };
  // const [searchQuery, setSearchQuery] = useState("");
  const handleSearch = (value: string): void => {
    try {
      const dataFiltered = filterData(value, dataArray as RubriqueSanteAnimalType[]);
      setFilterArray(dataFiltered);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <div style={{}}>
      <Box display="flex" justifyContent="flex-end">
        <form>
          <TextField
            id="search-bar"
            // className="text"
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleSearch(e.target.value);
            }}
            label="Rechercher"
            variant="outlined"
            placeholder="Recherche..."
            // size="small"
            className={classes.textField}
          />
          <IconButton>
            <SearchIcon style={{ fill: COLORS.primary }} />
          </IconButton>
        </form>
      </Box>
    </div>
  );
}
SearchBar.defaultProps = {
  dataArray: [],
  setFilterArray: () => {},
};
SearchBar.propTypes = {
  // dataArray: PropTypes.object,
  dataArray: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.objectOf(PropTypes.any),
  ]),
  setFilterArray: PropTypes.func,
};
export default SearchBar;
