const isLocalhost: boolean = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

interface Config {
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
}

async function registerValidSW(swUrl: string, config?: Config): Promise<void> {
  try {
    const registration = await navigator.serviceWorker.register(swUrl);

    registration.onupdatefound = () => {
      const installingWorker = registration.installing;
      if (installingWorker == null) {
        return;
      }
      installingWorker.onstatechange = () => {
        if (installingWorker.state === "installed") {
          if (navigator.serviceWorker.controller != null) {
            console.log(
              "New content is available and will be used when all " +
                "tabs for this page are closed. See https://cra.link/PWA."
            );

            if (config?.onUpdate != null) {
              config.onUpdate(registration);
            }
          } else {
            console.log("Content is cached for offline use.");

            if (config?.onSuccess != null) {
              config.onSuccess(registration);
            }
          }
        }
      };
    };
  } catch (error) {
    console.error("Error during service worker registration:", error);
  }
}

async function checkValidServiceWorker(swUrl: string, config?: Config): Promise<void> {
  try {
    const response = await fetch(swUrl, {
      headers: { "Service-Worker": "script" },
    });

    const contentType = response.headers.get("content-type");
    if (response.status === 404 || (contentType != null && !contentType.includes("javascript"))) {
      const registration = await navigator.serviceWorker.ready;
      await registration.unregister();
      window.location.reload();
    } else {
      await registerValidSW(swUrl, config);
    }
  } catch (error) {
    console.log("No internet connection found. App is running in offline mode.");
  }
}

function register(config?: Config): void {
  if (
    process.env.NODE_ENV === "production" &&
    "serviceWorker" in navigator &&
    process.env.PUBLIC_URL != null
  ) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      return;
    }

    window.addEventListener("load", () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        void checkValidServiceWorker(swUrl, config);

        navigator.serviceWorker.ready
          .then(() => {
            console.log(
              "This web app is being served cache-first by a service " +
                "worker. To learn more, visit https://cra.link/PWA"
            );
          })
          .catch((error) => {
            console.error("Error during service worker readiness:", error);
          });
      } else {
        void registerValidSW(swUrl, config);
      }
    });
  }
}

function unregister(): void {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then(async (registration: ServiceWorkerRegistration) => await registration.unregister())
      .catch((error: Error) => {
        console.error("Error during service worker unregistration:", error);
      });
  }
}

export { register, unregister };
