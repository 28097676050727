import React, { useEffect, useState } from "react";
import { Collapse } from "@mui/material";
import PropTypes from "prop-types";
// import ExpandLess from "@material-ui/icons/ExpandLess";
// import ExpandMore from "@material-ui/icons/ExpandMore";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { NavLink, useLocation } from "react-router-dom";

import globalStyles from "style";
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import { LAST_PATH } from "constant";

function SidenavNested({ route }): JSX.Element {
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");
  const [open, setOpen] = useState(false);
  const handleClick = (value: boolean): void => {
    setOpen(value);
  };
  useEffect(() => {
    /* if (!collapseName.includes(route.key as string)) {
      handleClick(false);
    } */
    handleClick(collapseName.includes(route.key as string));
  }, [collapseName]);
  const gobalClasses = globalStyles();
  // console.log("dd", collapseName, "traitements".substring(1));
  return (
    <>
      <NavLink
        key={route.key}
        to={route.route}
        onClick={() => {
          handleClick(true);
          // localStorage.setItem("lastPath", route.route);
        }}
      >
        <SidenavCollapse
          name={route.name}
          icon={route.icon}
          active={collapseName.includes(route.key as string)}
        >
          {open ? <ExpandLess /> : <ExpandMore />}
        </SidenavCollapse>
      </NavLink>
      <div>
        <Collapse in={open} timeout="auto" classes={gobalClasses.collapseMenu}>
          {route.collapse.map(
            (item) =>
              item.route !== route.key && (
                <span>
                  <NavLink
                    key={item.key}
                    to={item.route}
                    onClick={() => {
                      localStorage.setItem(String(LAST_PATH), item.route as string);
                    }}
                  >
                    <SidenavCollapse
                      name={item.name}
                      icon={item.icon}
                      active={item.route.substring(1) === collapseName}
                      // active={collapseName.includes(route.key as string)}
                      type="nested"
                    />
                  </NavLink>
                </span>
              )
          )}
        </Collapse>
      </div>
    </>
  );
}
SidenavNested.defaultProps = {
  // active: false,
};

// Typechecking props for the SidenavCollapse
SidenavNested.propTypes = {
  route: PropTypes.object.isRequired,
  // active: PropTypes.bool,
};

export default SidenavNested;
