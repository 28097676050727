import { makeStyles } from "@mui/styles";
import colors from "assets/theme/base/colors";

const globalStyles = makeStyles({
  centerContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  collapseMenu: {
    marginLeft: 20,
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    height: 120,
    backgroundColor: colors.success.main,
  },
  iconContainer: {
    marginTop: "auto",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
});
export default globalStyles;
