/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import DataTableContent from "components/DataTableRow/dataTableContent";
import { AUTO_COMPLETE_COMPONENT, INPUT_COMPONENT } from "constant/formComponentName";
import DataTableAction from "./../../../components/DataTableRow/dataTableAction";

export default function useUser() {
  const tableColumnsUser = [
    { Header: "nom", accessor: "nom", align: "center" },
    { Header: "email", accessor: "email", align: "center" },
    { Header: "role", accessor: "role", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  const tableRowsUser = (item, onSelectedRow) => {
    const userName = `${item.prenom} ${item.nom}`;
    const row = {
      nom: <DataTableContent title1={userName} />,
      email: <DataTableContent title2={item.email} />,
      role: <DataTableContent title2={item.role} />,
      action: <DataTableAction handleAction={onSelectedRow} item={item} />,
    };
    return row;
  };
  const formUser = () => {
    const listRole = [
      { id: 1, label: "ADMIN" },
      { id: 2, label: "USER" },
    ];
    const formConfig = [
      {
        name: "prenom",
        label: "Prenom *",
        initialValue: "",
        component: INPUT_COMPONENT,
        isVisible: true,
      },
      {
        name: "nom",
        label: "Nom *",
        initialValue: "",
        component: INPUT_COMPONENT,
        isVisible: true,
      },
      {
        name: "email",
        label: "Email*",
        initialValue: "",
        component: INPUT_COMPONENT,
        isVisible: true,
      },
      {
        name: "roleUser",
        label: "Role *",
        initialValue: "",
        component: AUTO_COMPLETE_COMPONENT,
        isVisible: true,
        list: listRole,
      },
      {
        name: "role",
        isVisible: false,
        initialValue: "",
      },
      /* {
        name: "indicateur",
        isVisible: false,
        initialValue: "+222",
      }, */
      {
        name: "password",
        isVisible: false,
        initialValue: "123456",
      },
      // Add more input configurations as needed
    ];
    return formConfig;
  };

  const formConfigUser = async () => {
    return formUser();
  };
  const validateFormUser = (name, value) => {
    // console.log("validateFormUser", name, value, value.length);
    let state = false;
    if (
      (name === "prenom" || name === "nom" || name === "email" || name === "role") &&
      value === ""
    ) {
      state = false;
    } else {
      state = true;
    }
    // console.log("validateFormUser end", state);
    return state;
  };
  return { tableColumnsUser, tableRowsUser, formConfigUser, validateFormUser };
}
