import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  // CardActionArea,
  CardContent,
  Typography,
  // Box,
  // IconButton,
  // Grid,
  // Paper,
} from "@mui/material";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { makeStyles } from "@mui/styles";
import globalStyles from "style";
// import colors from "assets/theme/base/colors";

const useStyles = makeStyles({
  card: {
    display: "flex",
    flexDirection: "column",
    height: "100%", // Ensure the card takes full height
    margin: "5px",
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginLeft: "auto", // Align the icon to the right side
  },
});

/*  Component qui affiche les éléments dans cardView */
function RubriqueCard({ cardData, handleOpenDialogAction }): JSX.Element {
  const classes = globalStyles();
  const classesCard = useStyles();
  // const maxLength = 25;
  const typographyRef = useRef<HTMLDivElement>(null);
  const [isOverflown, setIsOverflown] = useState(false);
  useEffect(() => {
    const checkOverflow = (): void => {
      const typographyElement = typographyRef.current;
      if (typographyElement !== null) {
        setIsOverflown(
          typographyElement.offsetWidth < typographyElement.scrollWidth &&
            typographyElement.offsetHeight < typographyElement.scrollHeight
        );
        // });
      }
    };

    checkOverflow();

    window.addEventListener("resize", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [cardData.title]);
  // console.log("jjkjk");
  return (
    <Card
      onClick={() => handleOpenDialogAction(cardData)}
      key={cardData.title}
      style={{ cursor: "pointer", padding: "20px" }}
    >
      <Card className={classesCard.card}>
        <CardContent
          className={`${classesCard.row} ${classes.centerContent}`}
          style={{
            height: "70px",
            marginTop: 15,
            marginBottom: 5,
            // display: "flex",
            // justifyContent: "center",
          }}
        >
          <Box display="flex" justifyContent="center">
            <Typography
              /* variant="h6" */ ref={typographyRef}
              variant="body1"
              noWrap={isOverflown}
              align="center"
            >
              {cardData.title}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Card>
  );
}

RubriqueCard.defaultProps = {
  // cardData: {},
  cardData: {
    id: 0,
    title: "",
    description: "",
  },
  handleOpenDialogAction: () => {},
  // handleOpenFile: () => {},
};
RubriqueCard.propTypes = {
  cardData: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  handleOpenDialogAction: PropTypes.func,
  // handleOpenFile: PropTypes.func,
};
export default RubriqueCard;
