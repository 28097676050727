/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useState, useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  Outlet,
  useNavigate,
  // HashRouter as Router,
} from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import Sidenav from "examples/Sidenav";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav } from "context";

// Images
// import Logoar from "assets/images/Logoar.png";
import logo from "assets/images/identite/logo.png";
import SignIn from "layouts/authentication/sign-in";
// ,import SignUp from "layouts/authentication/sign-up";
import Error404 from "layouts/error404/Error404";
import Logout from "layouts/authentication/sing-out";
import routesData from "router/routes";
import {
  PATH_MEDICAMENT,
  PATH_VACCIN,
  PATH_ANNEXE,
  PATH_MPSA,
  PATH_MALADIE_ZOONOTIQUE,
  PATH_MALADIE_NON_ZOONOTIQUE,
  PATH_LOGOUT,
  PATH_MALADIE_NON_OBLIGATOIRE,
} from "./constant/moduleInfo";
import RubriqueSanteAnimal from "layouts/gestion_sante_animale/rubrique";
import { LAST_PATH } from "constant";

// Vérifie si le token enregistré est expiré
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useAuth = () => {
  const userToken = localStorage.getItem("user-tokenBCMPSA");
  if (userToken !== null) {
    const authInfo = JSON.parse(userToken);
    const currentDate = new Date();
    if (authInfo.exp * 1000 > currentDate.getTime()) {
      return true;
    }
    return false;
  }
  return false;
};
export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, direction, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const [isAuth, setIsAuth] = useState(false);
  const [defaultPath, setdefaultPath] = useState("/logout");
  const navigate = useNavigate();

  // Au chargement de l'interface, vérification de la validité du token
  useEffect(() => {
    const AuthBool = useAuth();
    setIsAuth(AuthBool);
  });
  // si l'utilisateur est connecté , redirection vers son dernier interface
  useEffect(() => {
    const AuthBool = useAuth();
    if (AuthBool) {
      const lastPath = localStorage.getItem(String(LAST_PATH));
      // console.log("lastPath", lastPath);
      if (lastPath === null) {
        setdefaultPath(routesData[0].route);
        navigate(routesData[0].route);
      } else {
        navigate(lastPath);
      }
      // navigate(data_res[0].route);
    }
  }, []);
  // console.log("in useMemo", user_token);
  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (Boolean(miniSidenav) && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      // console.log("getRoutes start", route.collapse);
      if (route.collapse.length > 0) {
        // console.log("getRoutes collapse", route.route);
        return getRoutes(route.collapse);
      }

      // if (route.route) {
      // console.log("getRoutes not ", route.route);
      return <Route path={route.route} element={route.component} key={route.key} />;

      // return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && isAuth && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={logo}
            // brandName="Beta Clic"
            // routes={routes}
            routes={routesData}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            // user_roles={user_roles}
          />
        </>
      )}

      <Routes>
        <Route exact path="/" element={<ProtectedRoutes />}>
          <Route path="/" element={<Navigate replace to={defaultPath} />} />
          {getRoutes(routesData)}
          <Route path="*" element={<Error404 />} />
          <Route path={PATH_MEDICAMENT} element={<RubriqueSanteAnimal />} />
          <Route path={PATH_VACCIN} element={<RubriqueSanteAnimal />} />
          <Route path={PATH_ANNEXE} element={<RubriqueSanteAnimal />} />
          <Route path={PATH_MALADIE_ZOONOTIQUE} element={<RubriqueSanteAnimal />} />
          <Route path={PATH_MALADIE_NON_ZOONOTIQUE} element={<RubriqueSanteAnimal />} />
          <Route path={PATH_MALADIE_NON_OBLIGATOIRE} element={<RubriqueSanteAnimal />} />
          <Route path={PATH_LOGOUT} element={<Logout />} />
          {/* <Route exact path="/maladie" element={<IndexMaladie />} /> */}
        </Route>
        {/* <Route path="*" element={<Navigate to="/dashboard" />} /> */}
        <Route path="" element={<PublicRoutes />}>
          <Route path="/sign-in" element={<SignIn />} />
          {/* <Route path="/sign-up" element={<SignUp />} /> */}
        </Route>
      </Routes>
      {/* </Router> */}
    </ThemeProvider>
  );
}

const ProtectedRoutes = () => {
  const auth = useAuth();
  return auth ? <Outlet /> : <Navigate to="/sign-in" />;
};

const PublicRoutes = () => {
  const auth = useAuth();
  let lastPath = PATH_MPSA;
  if (auth) {
    lastPath = localStorage.getItem(String(LAST_PATH));
  }
  // return auth ? <Navigate to="/dashboard" /> : <Outlet />;
  return auth ? <Navigate to={lastPath} /> : <Outlet />;
};
