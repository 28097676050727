/* eslint-disable @typescript-eslint/no-confusing-void-expression */
import React, { useEffect, useState } from "react";
// import { Document, Page } from "react-pdf";
import PropTypes from "prop-types";
import {
  Dialog,
  Button /* DialogContent */,
  Stack,
  Box,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MDBox from "components/MDBox";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import IconButton from "@mui/material/IconButton";
// import CloseIcon from "@mui/icons-material/Close";
import { Document, Page, pdfjs } from "react-pdf";
import COLORS from "constant/colors";
// import "./iframeStyle.css";
// import "./PDFViewerStyle.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const PDFViewerDialog = ({
  pdfURL,
  open,
  handleClose,
  selectedItem,
  listMaladieDiagnostic,
  handleOpenMaladieFile,
  pdfDiagnosticMaladieURL,
  setPdfDiagnosticMaladieURL,
}): JSX.Element => {
  const [file, setfile] = useState("");
  const userAgent: string = window.navigator.userAgent;
  const isIOSDevice = /iPad|iPhone|iPod/.test(userAgent);
  // console.log("sssss", isIOSDevice);
  useEffect(() => {
    let fileUrl = "";
    if (listMaladieDiagnostic.length > 0 && pdfDiagnosticMaladieURL !== "") {
      setfile(pdfDiagnosticMaladieURL as string);
      fileUrl = pdfDiagnosticMaladieURL;
    } else {
      setfile(pdfURL as string);
      fileUrl = pdfURL;
    }
    if (isIOSDevice) {
      window.open(fileUrl, "_blank", "noopener,noreferrer");
    }
  }, [pdfURL, pdfDiagnosticMaladieURL]);

  const [numPages, setNumPages] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages);
  };
  // console.log("sssss////", pdfDiagnosticMaladieURL);
  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullScreen
    >
      {/* <DialogTitle id="customized-dialog-title">{selectedItem.title}</DialogTitle> */}
      <Button
        key={"close"}
        variant="contained"
        sx={{ color: "#000" }}
        onClick={() => handleClose()}
      >
        Retour
      </Button>
      {listMaladieDiagnostic.length > 0 && (
        <Box sx={{ mt: 2 }}>
          {/* <Button variant="text" onClick={() => setPdfDiagnosticMaladieURL("")}>
            {selectedItem.title}
          </Button> */}
          {pdfDiagnosticMaladieURL !== "" && (
            <MDBox
              display="flex"
              alignItems="center"
              sx={{ justifyContent: "center", alignItems: "center" }}
            >
              <IconButton onClick={() => setPdfDiagnosticMaladieURL("")}>
                <ArrowBackIcon fontSize="large" style={{ color: COLORS.primary }} />
                <Typography variant="h3" style={{ color: COLORS.primary }}>
                  {selectedItem.title}
                </Typography>
              </IconButton>
            </MDBox>
          )}
          <Stack
            spacing={2}
            sx={{
              backgroundColor: "#ffe1c3",
              padding: { xs: "10px", sm: "20px" }, // Padding ajusté selon la taille de l'écran
            }}
          >
            <div style={{ margin: 15 }}>
              <Grid container spacing={3} sx={{ justifyContent: "center", alignItems: "center" }}>
                {listMaladieDiagnostic.map((i) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    key={i.id}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      fullWidth
                      variant="outlined"
                      sx={{ color: "#000" }}
                      onClick={() => handleOpenMaladieFile(i.label, true)}
                    >
                      {i.label}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </div>
            {/* </Grid> */}
          </Stack>
        </Box>
      )}
      {!isIOSDevice && (
        <div style={{ width: "100%" }}>
          <Document
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={<div>Chargement PDF...</div>}
            // renderMode="svg"
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={window.innerWidth * 0.9} // Adjust for responsiveness
                renderTextLayer={false} // Disable text layer
                renderAnnotationLayer={false} // Optionally, disable annotations as well
                // scale={1.0}
              />
            ))}
          </Document>
        </div>
      )}
    </BootstrapDialog>
  );
};
/* const styles = {
  iframeContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: "hidden",
  },
  iframe: {
    width: "100%",
    height: "100%",
    border: "none",
    overflowY: "scroll",
  },
}; */

export default PDFViewerDialog;
PDFViewerDialog.defaultProps = {
  pdfURL: "",
  open: false,
  handleClose: () => {},
  selectedItem: {
    id: 0,
    title: "",
    description: "",
  },
  listMaladieDiagnostic: [],
  handleOpenMaladieFile: () => {},
  setPdfDiagnosticMaladieURL: () => {},
  pdfDiagnosticMaladieURL: "",
};
PDFViewerDialog.propTypes = {
  pdfURL: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedItem: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  listMaladieDiagnostic: PropTypes.object,
  handleOpenMaladieFile: PropTypes.func,
  setPdfDiagnosticMaladieURL: PropTypes.func,
  pdfDiagnosticMaladieURL: PropTypes.string,
};
